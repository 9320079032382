<template>
  <div>
    <b-card style="margin-bottom: 0px;">
      <b-card no-body>
        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
          <i class="fa fa-file-text-o"></i> Hasta Grupları ve Hastaneleri
          <b-button class="pull-right" variant="secondary" @click="save_hospital_group()"><i class="fa fa-save"></i> Kaydet </b-button>
        </b-card-header>
        <b-row style="padding: 3px;">
          <b-col sm="12" md="4">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                <i class="fa fa-building-o"></i> Hastane Grupları Listesi
                <b-button class="pull-right" variant="primary" size="sm" @click="d_showAddNewHospitalGroupModal = true"><i class="fa fa-plus"></i></b-button>
                <!-- <b-button class="pull-right" variant="warning" size="sm" @click=""><i class="fa fa-check"></i></b-button> -->
              </b-card-header>
              <b-row v-if="true">
                <b-col cols="12">
                  <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                    <template v-for="(hos_grp, hos_grp_ind) in hospital_group_list">
                      <b-row :class="f_calculateHospitalGroupClass(hos_grp_ind)" @click="f_selectThisHospitalGroup(hos_grp_ind)">
                        <b-col cols="12">
                          <i :class="[selected_group_ind === hos_grp_ind ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ hos_grp.label }}
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col sm="12" md="4" style="border-radius: 15px">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                <i class="fa fa-database"></i> Grup Hastaneleri
                <!-- <b-button class="pull-right" variant="warning" size="sm" @click=""><i class="fa fa-check"></i></b-button> -->
                <b-button :disabled="selected_group_ind === '' ? true : false" class="pull-right" variant="primary" size="sm" @click="d_showAddNewHospitalModal = true"><i class="fa fa-plus"></i></b-button>
              </b-card-header>
              <b-row v-if="hospital_group_list[selected_group_ind]">
                <b-col cols="12">
                  <div id="s_operationsScrollbar" style="min-height: 120px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                    <template v-for="(hos, hos_ind) in hospital_group_list[selected_group_ind].hospital_list">
                      <b-row :class="f_calculateHospitalClass(hos_ind)" @click="f_getHospital(hos_ind)">
                        <b-col cols="12">
                          <i :class="[selected_hospital_ind === hos_ind ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ hos.label }}
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col sm="12" md="4">
            <b-card no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                <i class="fa fa-asterisk"></i> Hastane Detayları
              </b-card-header>
              <template v-if="selected_group_ind !== '' && selected_hospital_ind !== '' && hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind]">
                <!-- <b-row>
                  <b-col cols="4">Hastane Adı</b-col>
                  <b-col cols="8">
                    <b-form-input v-model="hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind].label"></b-form-input>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col cols="4">Hastane Kodu</b-col>
                  <b-col cols="8">
                    <b-form-input disabled v-model="hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind].value"></b-form-input>
                    <!-- <template v-if="hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind].used === 0">
                    </template>
                    <template v-else>
                      <span style="color: red;">Hastane kodu ile kayıtlar oluşturulmuştur. Değiştirilemez.</span>
                    </template> -->
                  </b-col>
                </b-row>
                <!--                 <b-row>
                  <b-col cols="4">Hastane ID</b-col>
                  <b-col cols="8">
                    <b-form-input v-model="hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind].HospitalID"></b-form-input>
                  </b-col>
                </b-row> -->
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <template v-if="d_showTrueFalseHospitalData && selected_group_ind !== '' && selected_hospital_ind !== '' && hospital_group_list[selected_group_ind].hospital_list[selected_hospital_ind]">
      <hospital-data :hospital_data="hospital_data" :all_hospital="true" :hospital_wdm_list="hospital_wdm_list" :hospital_wtl_list="hospital_wtl_list"></hospital-data>
    </template>
    <modal v-if="d_showAddNewHospitalGroupModal" @close="d_showAddNewHospitalGroupModal = false">
      <h3 slot="header"> Hastane Grubu Ekleme Ekranı </h3>
      <div slot="body">
        <b-row>
          <b-col cols="4">Hastane Grubu Adı</b-col>
          <b-col cols="8">
            <b-form-input v-model="hospital_group.label"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">Hastane Grubu Kodu</b-col>
          <b-col cols="8">
            <b-form-input v-model="hospital_group.value"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showAddNewHospitalGroupModal = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-success fa fa-save" @click="f_addNewHospitalGroup()">{{ $t('wdm16.4061') }}</button>
      </div>
    </modal>
    <modal v-if="d_showAddNewHospitalModal" @close="d_showAddNewHospitalModal = false">
      <h3 slot="header"> Hastane Ekleme Ekranı </h3>
      <div slot="body">
        <b-row>
          <b-col cols="4">Hastane Adı</b-col>
          <b-col cols="8">
            <b-form-input v-model="new_hospital.label"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">Hastane Kodu</b-col>
          <b-col cols="8">
            <b-form-input v-model="new_hospital.value"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showAddNewHospitalModal = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-success fa fa-save" @click="f_addNewHospital()">{{ $t('wdm16.4061') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
// import {default as router} from '@/router';
import HospitalService from '@/services/hospital';
import Modal from '@/components/widgets/Modal';
import HospitalData from '@/components/widgets/HospitalData';
import OptionService from '@/services/option';

export default {
  name: 'AllHospitals',
  computed: {},
  data () {
    return {
      d_showTrueFalseHospitalData: false,
      d_showAddNewHospitalModal: false,
      d_showAddNewHospitalGroupModal: false,
      selected_group_ind: '',
      selected_hospital_ind: '',
      hospital_group_data: {},
      edit_hospital_in_group: { label: '', value: '' },
      edit_hospital_ind: '',
      hospital_group_list: [],
      hospital_group: { label: '', value: '', hospital_list: [] },
      new_hospital: { label: '', value: '' },
      edit_hospital_group: { label: '', value: '' },
      edit_hospital_group_ind: '',
      logo_list: ['logo_1', 'logo_2', 'logo_3', 'logo_4', 'logo_5'],
      selected_image: '',
      selected_file: '',
      department_manager: '',
      selected_dep_ind: '',
      department: '',
      person: '',
      telephone: '',
      hospital_data: { headers: { hospital_name: { translation: { tr: '', en: '' } }, recipe_drugs_module: { translation: { tr: '', en: '' } }, chemotherapy_protocol_scheme: { translation: { tr: '', en: '' } } }, address: '', departments: [], logo: { logo_1: '', logo_2: '', logo_3: '', logo_4: '', logo_5: '' }, hospital_id: '', id: '', center_phone_number: '' },
      hospital_wdm_list: {},
      hospital_wtl_list: []
    };
  },
  created: function () {
    this.get_hospital_group();
  },
  methods: {
    f_calculateHospitalGroupClass: function (hospital_group_ind) {
      if (this.selected_group_ind === hospital_group_ind) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    f_calculateHospitalClass: function (hospital_ind) {
      if (this.selected_hospital_ind === hospital_ind) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    f_selectThisHospitalGroup: function (hospital_group_ind) {
      this.d_showTrueFalseHospitalData = false;
      this.hospital_data = {};
      this.selected_hospital_ind = '';
      this.selected_group_ind = hospital_group_ind;
    },
    f_selectThisHospital: function (hospital_ind) {
      this.selected_hospital_ind = hospital_ind;
    },
    f_getHospital: function (hospital_ind) {
      this.hospital_wdm_list = {};
      this.d_showTrueFalseHospitalData = false;
      this.selected_hospital_ind = hospital_ind;
      let hospital_group = this.hospital_group_list[this.selected_group_ind].value;
      let hospital_id = this.hospital_group_list[this.selected_group_ind].hospital_list[this.selected_hospital_ind].value;
      let query = 'group=' + hospital_group + '&hospital=' + hospital_id;
      HospitalService.get(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_data = resp.data.result[0];
          } else {
            this.hospital_data = {
              'headers': {
                'hospital_name': { 'translation': { 'tr': '', 'en': '' } },
                'recipe_drugs_module': { 'translation': { 'tr': '', 'en': '' } },
                'chemotherapy_protocol_scheme': { 'translation': { 'tr': '', 'en': '' } }
              },
              'address': '',
              'departments': [],
              'logo': { 'logo_1': '', 'logo_2': '', 'logo_3': '', 'logo_4': '', 'logo_5': '' },
              'hospital_id': '',
              'id': '',
              'center_phone_number': ''
            };
            this.hospital_data.hospital_id = hospital_id;
            this.hospital_data.hospital_group = hospital_group;
          }
          let query = 'hospitalGroup=' + hospital_group + '&' + 'hospitalCode=' + hospital_id;
          OptionService.getHospitalWisdomDataModelList(query)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.hospital_wdm_list = resp.data.result;
              } else {
                this.hospital_wdm_list = {};
              }
              OptionService.getHospitalWtl(query)
                .then(resp => {
                  if (resp.data.status === 'success') {
                    this.hospital_wtl_list = resp.data.result;
                  } else {
                    this.hospital_wtl_list = [];
                  }
                  this.d_showTrueFalseHospitalData = true;
                });
            });
        });
    },
    get_hospital_group: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane grupları ve hastanelerinin son kullanım durumlarıyla birlikte analiz ediliyor. Lütfen bekleyiniz.' } });
      HospitalService.get_hospital_group()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            this.hospital_group_data = resp.data.result;
            this.hospital_group_list = resp.data.result.hospital_groups;
          } else {
            alert('Grup kaydı henüz yok.');
          }
        });
    },
    save_hospital_group: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane grubu ve seçili hastane detayları kaydediliyor.' } });
      HospitalService.save(this.hospital_data)
        .then(resp => {
          this.get_hospital_group();
        });
      // HospitalService.group_save(this.hospital_group_data)
      //   .then(resp => {
      //     if (resp.data.status === 'success') {
      //       msg += 'Hastane Grubu Kayıt işlemi tamamlandı.\n';
      //     } else {
      //       msg += 'Hastane Grubu Kayıt işlemi başarısız.\n';
      //     }
      //     HospitalService.save(this.hospital_data)
      //       .then(resp => {
      //         this.$store.commit('Loading', { 'status': false, 'data': {} });
      //         if (resp.data.status === 'success') {
      //           this.hospital_data = resp.data.result;
      //           msg += 'Hastane Kayıt işlemi tamamlandı.\n';
      //         } else {
      //           msg += 'Hastane Kayıt işlemi başarısız.\n';
      //         }
      //         let modal_data = { 'type': 'alert', 'text': msg, 'title': 'Hastane Kaydı Hakkında', 'centered': true };
      //         this.$store.commit('modal', modal_data);
      //         this.$store.commit('modal_show', true);
      //       });
      //   });
    },
    SaveEditHospital_ind_group: function () {
      this.hospital_group_list[this.edit_hospital_group_ind].hospital_list[this.edit_hospital_ind] = JSON.parse(JSON.stringify(this.edit_hospital_in_group));
      this.edit_hospital_in_group = '';
    },
    f_addNewHospital () {
      if (this.new_hospital.value && this.new_hospital.label) {
        let det = false;
        for (let i in this.hospital_group_list[this.selected_group_ind].hospital_list) {
          if (this.hospital_group_list[this.selected_group_ind].hospital_list[i].value === this.new_hospital.value) {
            det = true;
          }
        }
        if (!det) {
          this.hospital_group_list[this.selected_group_ind].hospital_list.push(JSON.parse(JSON.stringify(this.new_hospital)));
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane kayıt işlemi yapılıyor' } });
          this.d_showAddNewHospitalModal = false;
          let data = {'hospital_group': this.hospital_group_list[this.selected_group_ind].value, 'hospital_id': this.new_hospital.value, 'hospital_name': this.new_hospital.label};
          HospitalService.add_new_hospital_id_to_group(data)
            .then(resp => {
              this.get_hospital_group()
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
            });
        } else {
          alert('Hastane bu grupta eklenmiş.');
        }
      } else {
        alert('Lütfen alanları doldurunuz');
      }
    },
    f_addNewHospitalGroup: function () {
      if (this.hospital_group.value && this.hospital_group.label) {
        let det = false;
        for (let i in this.hospital_group_list) {
          if (this.hospital_group_list[i].value === this.hospital_group.value) {
            det = true;
          }
        }
        if (!det) {
          this.hospital_group_list.push(this.hospital_group);
          this.d_showAddNewHospitalGroupModal = false;
          let data = {'hospital_group': this.hospital_group.value, 'hospital_group_name': this.hospital_group.label};
          HospitalService.add_new_hospital_group(data)
            .then(resp => {
              this.get_hospital_group()
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
            });
        } else {
          alert('Bu grup tanımlı');
        }
      } else {
        alert('Lütfen alanları doldurunuz');
      }
    },
    EditHospitalGroup: function (hos_grp, hos_grp_ind) {
      this.edit_hospital_group = JSON.parse(JSON.stringify(hos_grp));
      this.edit_hospital_group_ind = hos_grp_ind;
    },
    SaveEditHospitalGroup: function () {
      this.hospital_group_list[this.edit_hospital_group_ind] = JSON.parse(JSON.stringify(this.edit_hospital_group));
      this.edit_hospital_group = '';
    },
    onFileChange (logo_ind) {
      // var files = e.target.files || e.dataTransfer.files;
      var files = document.getElementById(logo_ind).files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], logo_ind);
    },
    createImage (file, logo_ind) {
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.hospital_data.logo[logo_ind] = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    /*    onFileSelected (event) {
          for (let i in event.target.files) {
            this.selected_file = event.target.files[i];
          }
        }, */
    DeletePhone (dep_ind, ph_ind) {
      this.hospital_data.departments[dep_ind].phones.splice(ph_ind, 1);
    },
    DeleteDepartment (dep_ind) {
      this.hospital_data.departments.splice(dep_ind, 1);
    },
    add_department: function () {
      let det = false;
      for (let i in this.hospital_data.departments) {
        if (this.hospital_data.departments[i].name === this.department) {
          det = true;
          alert('Bu departman sistemde mevcuttur.');
          break;
        }
      }
      if (!det) {
        this.hospital_data.departments.push({ name: this.department, manager: this.department_manager, phones: [] });
      }
    },
    add_new_phone: function () {
      if (this.selected_dep_ind !== '' && this.hospital_data.departments[this.selected_dep_ind]) {
        let det = false;
        for (let i in this.hospital_data.departments[this.selected_dep_ind].phones) {
          if (this.hospital_data.departments[this.selected_dep_ind].phones[i].no === this.telephone) {
            det = true;
            alert('Bu no zaten kayıtlı');
          }
        }
        if (!det) {
          this.hospital_data.departments[this.selected_dep_ind].phones.push({ person: this.person, no: this.telephone });
        }
      }
    },
    save: function () {
      this.hospital_data.hospital_group = this.hospital_group_list[this.selected_group_ind].value;
      this.hospital_data.hospital_id = this.hospital_group_list[this.selected_group_ind].hospital_list[this.selected_hospital_ind].value;
      HospitalService.save(this.hospital_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_data = resp.data.result;
            alert(this.$t('wdm16.12661'));
          } else {
            alert('Kayıt işlemi başarısız.');
          }
        });
    }
  },
  components: {
    Modal,
    HospitalData
  }
};

</script>

<style type="text/css">
.s_rowSelected {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowSelected:hover {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected {
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

#s_operationsScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_operationsScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#s_operationsScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

